<template>
  <div class="support">
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('18.1'))" />
    <div class="container">
      <div class="wrap">
        <div class="item">
          <n-link :to="{name: 'contacts'}"></n-link>
          <div class="left">
            <div class="img"><n-icon icon="contact" /></div><div class="title"><t name="18.2" /></div>
          </div>
          <div class="right"><n-icon icon="right-arrow" /></div>
        </div>

<!--        <div class="item">-->
<!--          <n-link :to="{name: 'newApplication'}"></n-link>-->
<!--          <div class="left">-->
<!--            <div class="img"><n-icon icon="new-order" /></div><div class="title"><t name="18.3" /></div>-->
<!--          </div>-->
<!--          <div class="right"><n-icon icon="right-arrow" /></div>-->
<!--        </div>-->

<!--        <div class="item">-->
<!--          <n-link :to="{name: 'my.orders'}"></n-link>-->
<!--          <div class="left">-->
<!--            <div class="img"><n-icon icon="my-order" /></div><div class="title"><t name="18.4" /></div>-->
<!--          </div>-->
<!--          <div class="right"><n-icon icon="right-arrow" /></div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Support',
}
</script>

<style scoped lang="scss">
.support {
  .wrap {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: 1px solid #D9D9D9;
      position: relative;

      .n-link{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .img{
        margin-right: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left{
        display: flex;
        align-items: center;
      }
    }

    .title{
      font-weight: 400;
      font-size: 18px;
      color: #102E4F;
    }
  }
}
</style>
